@import "~antd/dist/antd.css";

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url("./../fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("./../fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("./../fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("./../fonts/poppins/Poppins-SemiBold.ttf");
}

/**Scroll Css */
body::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #c8c8c8;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  /* border: 3px solid black */
  /* creates padding around scroll thumb */
}

/* width */

::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c8c8c8;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c8c8c8;
}

/*End Scroll Css */

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif !important;
  background: #f1f2f7 !important;
}

/*---------------------------------------------*/
a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #333333;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}

textarea:-moz-placeholder {
  color: #adadad;
}

textarea::-moz-placeholder {
  color: #adadad;
}

textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.hidden {
  display: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #999999;
  line-height: 1.5;
}

.txt2 {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #57b846;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.wrap-login100 {
  width: 400px;
  background: #fff;
  padding: 20px;
  font-family: "Open Sans", "Helvetica", sans-serif !important;
  border: 1px solid #eaeaea;
  box-shadow: 0 5px 10px 0px rgba(51, 51, 51, 0.3);
}

.input-login-email {
  margin-bottom: 10px;
}

.input-login-pass {
  margin-bottom: 15px;
}

.fas-login-desc {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #333333;
  font-family: Verdana, Tahoma, sans-serif;
}

.forget-pass {
  text-align: center;
  margin-top: 10px;
}

.link {
  text-decoration: none;
  font-size: 12px !important;
  font-family: "Poppins-Regular";
}

.link-react {
  color: #607799 !important;
  padding: 8px 15px;
  font-size: 13px !important;
}

.link-button {
  color: #1890ff;
}

.link-react:hover {
  background-color: #e0e6ef !important;
  color: #212529 !important;
}

.login-footer {
  font-family: "Open Sans", "Helvetica", sans-serif !important;
  color: #999999;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.login-term-link {
  font-size: 12px;
  font-family: inherit;
  color: #007bff;
}

.rem-me {
  margin-bottom: 15px;
}

.rem-me-check {
  vertical-align: middle;
}

.rem-me-label {
  margin-left: 5px;
  font-size: 14px;
  margin-bottom: 0px;
  vertical-align: middle;
}

.group-label {
  opacity: 0.4;
}

/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-family: Poppins-Bold;
  font-size: 20pt;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}

.we-back {
  font-size: 42px;
  font-style: italic;
}

.login100-form-avatar {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-avatar img {
  width: 100%;
}

/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #999999;
}

.input100 {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 10pt;
  color: #000000;
  border: 1px solid gray;
  border-radius: 2px;
  display: block;
  width: 100%;
  padding: 8px;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #57b846;
}

.focus-input100::after {
  font-family: Poppins-Medium;
  font-size: 18px;
  color: #999999;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 15px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 30px;
  background-color: #3d8bd6;
  border-radius: 5px;

  /*box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);*/

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;

  font-family: Poppins-Regular;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #c80000;

  display: block;
  position: absolute;
  background-color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/*//////////////////////////////////////////////////////////////////
[ Login more ]*/
.login-more li {
  position: relative;
  padding-left: 16px;
}

.login-more li::before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #cccccc;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}

.header-main {
  float: left;
  width: 100%;
  padding: 0;
  position: fixed;
  z-index: 99;
}

.header-inner {
  width: 100%;
  padding: 0 25px;
  height: 60px;
  float: left;
  background-color: #fff;
}

.f-logo {
  font-family: Poppins-Bold;
  font-size: 30px;
  color: #333;
  margin-top: 7px;
  float: left;
  text-decoration: none;
  transition: none;
  -webkit-transition: none;
  -o-transition: none;
  -moz-transition: none;
}

.f-logo-login {
  font-family: Poppins-Bold;
  font-size: 30px;
  color: #333;
  margin-top: 7px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  width: fit-content;
  text-decoration: none;
  transition: none;
  -webkit-transition: none;
  -o-transition: none;
  -moz-transition: none;
}

.company-logo {
  float: left;
  max-width: 150px;
  margin-top: 20px;
  margin-left: 20px;
}

.company-logo img {
  max-width: 150px;
}

.company-logo svg {
  fill: blue !important;
  width: 150px;
  height: 28px;
}

.company-logo svg:hover path {
  fill: blue !important;
}

.header-right {
  float: right;
}

.f-main-menu {
  float: left;
  width: 100%;
  background-color: #607799;
}

.home-menu-icon {
  color: white;
  font-size: 22px;
}

.header-right .nav-item {
  margin-top: 12px;
  float: right;
}

.header-right .nav-item .nav-link {
  padding-left: 25px;
  color: #666666;
}

.header-right .nav-item .nav-link i {
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 8px;
}

.feed-back {
  float: left;
  margin-top: 12px;
  padding: 0.5rem 1rem;
  position: relative;
  padding-left: 26px;
  margin-right: 10px;
  color: #666666;
}

.feed-back i {
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 8px;
}

.nav-link img {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: -8px 0;
  float: left;
  margin-right: 10px;
}

.header-right .nav-item .dropdown-menu a {
  color: #777;
  padding: 5px 20px;
  line-height: normal;
}

.header-right .dropdown-menu {
  width: 11rem;
}

.header-right .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.menu-area .container-fluid {
  padding: 0 25px;
}

.menu-area .container-fluid .row {
  padding: 0;
  margin: 0;
}

.menu-area .dropdown-menu {
  background-color: #f1f1f1;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 0;
  border-top: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
}

.mainmenu a,
.navbar-default .navbar-nav > li > a,
.mainmenu ul li a,
.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff;
  font-size: 14px !important;
  text-transform: capitalize;
  padding: 9px 15px 8px 16px;
  font-family: Poppins-Medium;
  display: block !important;
  transition: none;
  text-decoration: none;
  -webkit-transition: none;
  -o-transition: none;
  -moz-transition: none;
}

.mainmenu .collapse ul ul li a {
  color: #607799;
  padding: 8px 15px;
  font-size: 14px;
  text-decoration: none;
}

.mainmenu .active a,
.mainmenu .active a:focus,
.mainmenu .active a:hover,
.mainmenu li a:hover,
.mainmenu li a:focus,
.navbar-default .navbar-nav > .show > a,
.navbar-default .navbar-nav > .show > a:focus,
.navbar-default .navbar-nav > .show > a:hover {
  color: #fff;
  background: #24385b;
  outline: 0;
}

/*==========Sub Menu=v==========*/
.mainmenu .collapse ul > li:hover > a {
  background: #e0e6ef;
  color: #24385b;
}

.mainmenu .collapse ul ul > li:hover > a,
.navbar-default .navbar-nav .show .dropdown-menu-2 > li > a:focus,
.navbar-default .navbar-nav .show .dropdown-menu-2 > li > a:hover {
  background: #e0e6ef;
  color: #24385b;
}

.mainmenu .collapse ul ul ul > li:hover > a {
  background: #e0e6ef;
  color: #24385b;
}

.mainmenu .collapse ul ul ul ul,
.mainmenu .collapse ul ul ul ul.dropdown-menu-2 {
  background: #64b5f6;
}

.mainmenu .collapse ul ul li.dropdown a.dropdown-toggle:after {
  content: "\f105";
  font-family: "FontAwesome";
  border: none;
  position: absolute;
  font-size: 27px;
  right: 30px;
  top: -5px;
}

.menu_area .navbar {
  padding: 0;
}

.pad-0 {
  padding: 0px !important;
}

/******************************Drop-down menu work on hover**********************************/
.mainmenu {
  background: none;
  border: 0 solid;
  margin: 0;
  padding: 0;
  min-height: 20px;
  width: 100%;
}

.main-content {
  float: left;
  width: 100%;
  margin-top: 40px;
}

.group:before,
.group:after {
  content: "";
  display: table;
}

.group:after {
  clear: both;
}

.report-container {
  max-width: 990px;
  min-height: 500px;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  top: 100px;
  box-shadow: 0 5px 10px 0px rgba(51, 51, 51, 0.3);
}

.report-container h1 {
  float: left;
  font-size: 20px;
  font-family: Poppins-Medium;
  color: #fff;
  padding: 10px 10px 10px 14px;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.report-container button {
  float: right;
  align-items: center;
}

.report-container-header {
  float: left;
  width: 100%;
  background-color: #607799;
  font-size: 20px;
  font-family: Poppins-Medium;
  color: #fff;
}

.report-container-inner {
  float: left;
  width: 100%;
  padding: 20px;
  margin-top: 10px;
}

.report-container-inner label {
  margin-bottom: 5px;
}

.r-left {
  float: left;
  width: 65%;
}

.date-filter {
  float: left;
  width: 100%;
  border: solid 1px #d4d2d2;
  min-height: 50px;
  position: relative;
  border-radius: 5px;
  padding: 5px 15px 10px 15px;
  margin-bottom: 20px;
}

.filter-title {
  font-size: 14px;
  color: #333;
  background-color: #fff;
  font-family: Poppins-Medium;
  position: absolute;
  top: -8px;
  left: 15px;
  padding: 0 6px;
  letter-spacing: 1px;
}

.dt-inr {
  width: 48%;
  margin-top: 20px;
}

.dt-inr-left {
  float: left;
}

.dt-inr-right {
  float: right;
}

.dt-inr label {
  float: left;
  width: 100%;
  font-size: 14px;
}

.dt-inr input[type="date"] {
  float: left;
  width: 100%;
  border: solid 1px #999;
  height: 35px;
  padding: 0 10px;
  cursor: pointer;
  font-size: 14px;
}

.status-filter {
  float: left;
  width: 100%;
  border: solid 1px #d4d2d2;
  min-height: 50px;
  border-radius: 5px;
  position: relative;
  padding: 5px 15px 10px 15px;
  margin-bottom: 20px;
}

.btns-main {
  float: left;
  width: 100%;
  padding: 10px 5px 0 5px;
}

span.st-btns {
  width: 33%;
  float: left;
}

span.st-btns label {
  padding-left: 0;
  letter-spacing: 1px;
  cursor: pointer;
}

span.st-btns label span {
  padding-left: 6px;
  font-size: 14px;
  position: relative;
  top: -1px;
}

.slt-filters {
  float: left;
  width: 100%;
  border: solid 1px #d4d2d2;
  min-height: 50px;
  border-radius: 5px;
  position: relative;
  padding: 5px 15px 15px 15px;
  margin-bottom: 20px;
}

.slt-filter-iner {
  float: left;
  width: 100%;
  margin-top: 0;
}

.b-ftr-inr {
  float: left;
  width: 100%;
  margin-top: 10px;
}

.b-ftr-inr label {
  float: left;
  width: 140px;
  margin-top: 7px;
  font-size: 14px;
}

.b-ftr-inr select {
  float: left;
  width: 360px;
  border: solid 1px #999;
  height: 35px;
  padding-left: 10px;
  font-size: 14px;
}

.auto-complete-text {
  float: left;
  width: 360px;
  border: solid 1px #999;
  height: 35px;
  padding-left: 10px;
  font-size: 14px;
  border-radius: 3px;
}

.b-ftr-inr [type="text"],
[type="number"] {
  float: left;
  width: 360px;
  border: solid 1px #999;
  height: 35px;
  padding-left: 10px;
  font-size: 14px;
}

/*Hide spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.b-ftr-inr input[type="button"],
.b-ftr-inr input[type="submit"] {
  float: right;
  background: #24385b;
  color: #fff;
  height: 35px;
  width: 70px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 14px;
}

.b-ftr-inr input[type="button"]:hover,
.b-ftr-inr input[type="submit"]:hover {
  background-color: #333;
}

.r-right {
  float: right;
  width: 30%;
}

.report-filter-right {
  float: left;
  width: 100%;
  border: solid 1px #d4d2d2;
  min-height: 50px;
  position: relative;
  border-radius: 5px;
  padding: 5px 15px 10px 15px;
  margin-bottom: 20px;
}

.btns-main-right {
  float: left;
  width: 100%;
  padding: 10px 5px 0 5px;
}

span.st-btns-right {
  width: 100%;
  float: left;
}

span.st-btns-right label {
  padding-left: 0;
  letter-spacing: 1px;
  cursor: pointer;
}

span.st-btns-right label span {
  padding-left: 6px;
  font-size: 14px;
  position: relative;
  top: -1px;
}

span.st-btns-right-2 {
  width: 50%;
  float: left;
}

span.st-btns-right-2 label {
  padding-left: 0;
  letter-spacing: 1px;
  cursor: pointer;
}

span.st-btns-right-2 label span {
  padding-left: 4px;
  font-size: 14px;
  position: relative;
  top: -1px;
}

.report-submit {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
}

.report-submit-btn {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #fff;
  background-color: #607799 !important;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 40px;
  background-color: #333;
  border-radius: 4px;
  cursor: pointer;

  /*box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(87, 184, 70, 0.5);*/

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.report-submit-btn:hover {
  background-color: #333333;
  box-shadow: 0 5px 10px 0px rgba(51, 51, 51, 0.3);
  -moz-box-shadow: 0 5px 10px 0px rgba(51, 51, 51, 0.3);
  -webkit-box-shadow: 0 5px 10px 0px rgba(51, 51, 51, 0.3);
  -o-box-shadow: 0 5px 10px 0px rgba(51, 51, 51, 0.3);
  -ms-box-shadow: 0 5px 10px 0px rgba(51, 51, 51, 0.3);
}

.report-container-inner input[type="checkbox"],
.report-container-inner input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
}

textarea:focus,
input:focus,
select {
  border-color: #607799 !important;
}

@media only screen and (min-width: 767px) {
  .mainmenu .collapse ul li:hover > ul {
    display: block;
  }

  .mainmenu .collapse ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 315px;
    display: none;
  }

  /*******/
  .mainmenu .collapse ul ul li {
    position: relative;
  }

  .mainmenu .collapse ul ul li:hover > ul {
    display: block;
  }

  .mainmenu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 292px;
    display: none;
  }

  /*******/
  .mainmenu .collapse ul ul ul li {
    position: relative;
  }

  .mainmenu .collapse ul ul ul li:hover ul {
    display: block;
  }

  .mainmenu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 250px;
    display: none;
    z-index: 1111;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-body .navigation-area {
    float: none !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .navbar-nav .show .dropdown-menu-2 .dropdown-menu-2 > li > a {
    padding: 16px 15px 16px 35px;
  }

  .navbar-nav
    .show
    .dropdown-menu-2
    .dropdown-menu-2
    .dropdown-menu-2
    > li
    > a {
    padding: 16px 15px 16px 45px;
  }
}

@media only screen and (max-width: 991px) {
  .menu-area {
    background-color: #607799;
  }

  .navbar-light .navbar-toggler {
    border: none;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
}

.mainmenu .collapse ul ul li a:hover {
  color: #212529;
}

.link {
  padding: 0px !important;
  font-size: 12px !important;
  text-decoration: none;
}

.f-logo:hover {
  text-decoration: none;
  color: #333;
}

.auto-complete-text-btn {
  border-radius: 5px;
  float: right;
  padding: 8px 7px 5px 8px;
  color: white;
  background-color: #607799 !important;
  font-family: "Poppins-Regular";
  font-size: 16px;
}

.dash-card-left-div {
  float: left;
  width: 25%;
  padding: 10px 10px 10px 10px;
}

.dash-card-right-div {
  float: right;
  width: 75%;
  padding: 10px 10px 10px 0px;
}

.dashbaord-card-left {
  height: fit-content;
  float: left;
  width: 100%;
  background-color: white;
  position: relative;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 3px rgb(145, 144, 144);
}

.dashbaord-card-right {
  height: fit-content;
  float: right;
  background-color: white;
  position: relative;
  border-radius: 6px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 3px #ddd;
  box-shadow: 1px 1px 3px rgb(145, 144, 144);
}

.dashboard-cards {
  max-width: 1200px;
  margin: 0 auto;
  display: none;
  padding: 5px;
  font-size: 21px;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.dashboard-card {
  background-color: #607799;
  color: white;
  border-radius: 0.5em;
  text-align: center;
  padding: 5rem;
  height: 4rem;
  font-size: 15px;
}

.dashboard-card span i {
  position: relative;
  top: 5px;
  right: 5px;
  font-size: 41px;
}

.dashboard-cards i.fa.fa-angle-left {
  font-size: 20px;
}

.dashboard-cards span.previous.back-button {
  font-size: 16px;
  color: #607799;
  text-align: center;
}

.dashboard-card a {
  font-size: 15px !important;
  color: white !important;
}

.dash-body {
  margin-top: 140px;
  float: left;
  width: 100%;
}

.dashbaord-card label {
  font-size: 16px;
  font-weight: 600;
}

.no-data-found {
  text-align: center;
  margin: auto;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px !important;
  width: fit-content;
  display: flex;
  color: #666666;
}

.no-snap {
  margin: auto;
  display: flex;
  width: fit-content;
  font-size: 12px;
}

.auto-select {
  float: left;
  width: 360px;
}

.Dilogue_Box_Title {
  float: left;
  width: 100%;
  padding: 16px 24px;
}

.Dilogue_Box_Title h2 {
  float: left;
}

.Dilogue_Box_Title button {
  float: right;
  width: 30px;
  height: 30px;
  background-image: url(../images/icons/cancel.svg);
}

.Dilogue_Box_Title button i {
  font-size: 20pt;
}

.MuiDialog-paperScrollPaper {
  max-height: calc(100%) !important;
}

.MuiDialog-paper {
  margin: 200px 0 0 0 !important;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.report-result {
  float: left;
  width: 100%;
  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  background-color: #fff;
  position: fixed;
  z-index: 1;
  top: 104px;
  overflow-y: auto;
}

.report-result-head {
  float: left;
  width: 100%;
  padding: 16px 24px;
  position: fixed;
  background-color: #fff;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.report-result-head h2 {
  float: left;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  line-height: 1px;
}

.navigation-area {
  margin-top: -30px;
  position: absolute;
  bottom: 0;
  left: 407px;
  text-align: center;
  margin-bottom: 5px;
}

.ant-modal-body .navigation-area {
  position: initial;
}

.ecommerce-dashbaord .navigation-area {
  position: initial;
  width: 88%;
  text-align: left;
  clear: left;
  float: left;
  margin-top: 1.8%;
}

.net-shipped-fit-style-dashboard .navigation-area {
}

.ecommerce-dashbaord .navigation-area a {
  margin-bottom: 10px;
}

.ecommerce-dashbaord .report-data {
  top: 225px !important;
}

.ecommerce-dashbaord .navigation-area a {
  text-transform: uppercase;
  display: inline-block;
  font-size: 11px !important;
  width: 116px;
  padding: 6px;
}

.ecommerce-dashbaord .report-result-right-top {
  float: right;
  margin-top: -47px;
}

.navigation-area .selected {
  background-color: #e3e5e8;
  color: black !important;
}

.report-result-right-top {
  float: right;
  margin-top: -31px;
}

.net-shipped-by-gender .navigation-area {
  width: 65%;
  /* margin: 0 auto; */
  text-align: center;
  clear: both;
  float: none;
  margin: 0 auto;
}

.net-shipped-by-gender .report-result-right-top {
  margin-top: -35px;
}

button#close-dialog-btn {
  float: right;
  width: 30px;
  height: 30px;
  background-image: url(../images/icons/close-icon.svg);
  background-size: cover;
  margin: 0;
  padding: 0;
  font-size: 0;
}

button#close-report-btn {
  float: right;
  width: 30px;
  height: 30px;
  background-image: url(../images/icons/close-report.png);
  padding: 0;
  font-size: 0;
  background-repeat: no-repeat;
  margin-top: 8px;
  margin-right: 4px;
}

button#download-pdf-button {
  float: left;
  margin-right: 60px;
  background-image: url(../images/icons/pdf-download.svg);
  background-repeat: no-repeat;
  height: 30px;
  background-color: white;
  padding-left: 42px;
  line-height: 32px;
  font-size: 14px;
}

button#email-button {
  float: left;
  margin-right: 60px;
  background-image: url(../images/icons/email.svg);
  background-repeat: no-repeat;
  height: 30px;
  background-color: white;
  padding-left: 42px;
  line-height: 32px;
  font-size: 14px;
}

button#download-pdf-button:hover,
button#email-button:hover {
  text-decoration: underline;
}

button#download-excel-button {
  float: left;
  margin-right: 40px;
  background-image: url(../images/icons/excel-download.svg);
  background-repeat: no-repeat;
  height: 30px;
  padding-left: 42px;
  line-height: 32px;
  font-size: 14px;
}

button#download-excel-button:hover {
  text-decoration: underline;
}

button#download-csv-button {
  float: left;
  margin-right: 40px;
  background-image: url(../images/icons/csv-download.svg);
  background-repeat: no-repeat;
  height: 30px;
  padding-left: 42px;
  line-height: 32px;
  font-size: 14px;
  background-size: 30px 30px;
}

button#download-csv-button:hover {
  text-decoration: underline;
}

.send-email-form {
  width: 50% !important;
}

.send-email-form button#email-button {
  float: none;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: #c8c8c8;
}

.ant-form-item-control-input .css-tlfecz-indicatorContainer {
  display: none;
}

.show-dialog {
  display: block;
}

.hide-dialog {
  display: none;
}

.report-data {
  float: left;
  width: 100%;
  position: relative;
  top: 55px;
  padding: 16px 24px;
}

/*Dashboard Report Data*/
.dash-body .report-data {
  top: 56px;
}

a:not([href]):not([class]) {
  display: inline;
}

.ant-table-column-sorters {
  padding: 8px 8px;
}

/** Dashboard Report word break remove */
/* .dash-body .ant-table-tbody>tr>td {
  white-space: nowrap;
} */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 6px 6px;
  font-family: sans-serif;
  vertical-align: bottom !important;
}

.ant-pagination-item-link {
  line-height: 32px;
}

.anticon svg {
  vertical-align: unset;
}

.total-results {
  float: left;
  position: relative;
  top: -50px;
  border: 1px solid gray;
  padding: 10px;
}

.total-results p {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.summary-title {
  border-bottom: 1px solid gray;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.ant-table-thead > tr > th {
  font-weight: bold;
}

/*.popUpTable table > tbody > tr:last-child td:nth-child(1),.popUpTable table > tbody > tr:last-child td:nth-child(2),.popUpTable table > tbody > tr:nth-last-child(2)  td:nth-child(1),.popUpTable table > tbody > tr:nth-last-child(2)  td:nth-child(2){
  border-right: none  !important;
}
*/

.popUpTable .ant-table-thead > tr > th,
.popUpTable .ant-table-tbody > tr > td,
.popUpTable .ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 1px 6px;
}

.popUpTable .ant-table-thead > tr > th,
.popUpTable .ant-table-tbody > tr > td,
.popUpTable .ant-table tfoot > tr > th,
.popUpTable .ant-table tfoot > tr > td {
  border-right: none !important;
  border-left: none !important;
  border-collapse: collapse;
}

.popUpTable .ant-table-tbody > tr:last-child,.popUpTable .ant-table-tbody > tr:last-child {
  font-weight: bold;
}

.bold-row {
  font-weight: bold !important;
}

.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

/*-----------------------Sales Comparison Table -----------------------------------------*/
.comparisonTable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #c8c8c8;
}

.comparisonTable .ant-table-thead > tr > th {
  border-left: 1px solid #c8c8c8;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
}

.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td {
  border-left: 1px solid #c8c8c8;
}

.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr:last-child
  td
  button,
.comparisonTable.popUpTableComparison
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  td:nth-child(2)
  button {
  pointer-events: none;
  color: black;
}

/*Remove Row Border*/
/* .comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(5),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(6),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(8),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(12),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(13),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(15),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(16),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(17),
.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(18) {
   border-left: 0 !important; 
} */

.ecommerce-by-region-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(9),
.ecommerce-by-region-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.top-style-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(12),
.top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(13),
.top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(14),
.net-shipped-by-sales-person-top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(12),
.net-shipped-by-sales-person-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(13),
.net-shipped-by-sales-person-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(14),
.net-shipped-by-sale-person-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.net-shipped-by-sale-person-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11) {
  background: #c8c8c8;
}

.net-shipped-by-gender-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(9),
.net-shipped-by-gender-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10) {
  background: #c8c8c8;
}

.ecommerce-by-region-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.top-style-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(9),
.top-style-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(12),
.top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(9),
.top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(12),
.top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(9),
.net-shipped-by-region-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.net-shipped-by-sales-person-top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(12),
.net-shipped-by-sales-person-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.net-shipped-by-sales-person-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.net-shipped-by-region-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(9),
.net-shipped-by-region-top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(12),
.net-shipped-by-region-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.net-shipped-by-region-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.top-size-table.popUpTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.top-size-table.popUpTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(12),
.net-shipped-by-customer-top-30
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.net-shipped-by-customer-top-30
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.net-shipped-by-fit-style-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(13),
.net-shipped-by-top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.net-shipped-by-top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.net-shipped-by-fit-style-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.net-shipped-by-fit-style-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.popUpTable.top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10) {
  background: none;
}

.top-style-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.net-shipped-by-region-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.net-shipped-by-region-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(12),
.net-shipped-by-region-top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.net-shipped-by-region-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(13),
.net-shipped-by-region-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(14),
.top-size-table.popUpTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(14),
.top-size-table.popUpTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(15),
.net-shipped-by-customer-top-30
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(10),
.net-shipped-by-customer-top-30
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11),
.net-shipped-by-top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(13),
.net-shipped-by-top-sku-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(14),
.net-shipped-by-fit-style-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(13),
.net-shipped-by-fit-style-top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(14),
.top-size-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(11) {
  background: #c8c8c8;
}

.net-shipped-by-sale-person-table
  th.ant-table-cell.ant-table-column-has-sorters,
.net-shipped-by-gender-table th.ant-table-cell.ant-table-column-has-sorters {
  border-bottom: 1px dotted black !important;
}

.net-shipped-by-sale-person-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr:not(:last-child)
  > th,
.net-shipped-by-gender-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr:not(:last-child)
  > th {
  border-bottom: 1px solid black;
}

/* .net-shipped-by-gender-table .ant-table-thead>tr>th { 
  border:1px solid ;
} 

.net-shipped-by-gender-table .ant-table-thead>tr>th:nth-child(4) { 
  border-left:none;
 } */

.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:last-child {
  border-right: 1px solid #c8c8c8;
}

.comparisonTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr:last-child
  > td {
  border-bottom: 1px solid #c8c8c8;
}

/*Remove Column Borderr*/
/* .comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(3),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(2),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(5),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(7),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(8),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(9),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(10),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(12),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(13),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(14),
.comparisonTable .ant-table-thead > tr:nth-child(2) > th:nth-child(15) {
  border-left: 0;
} 

/*Adding missing column border bottom*/
.comparisonTable .ant-table-thead > tr:nth-child(1) > th:nth-child(1),
.comparisonTable .ant-table-thead > tr:nth-child(1) > th:nth-child(2),
.comparisonTable .ant-table-thead > tr:nth-child(1) > th:nth-child(3),
.comparisonTable .ant-table-thead > tr:nth-child(1) > th:nth-child(8) {
  border-bottom: 1px solid  #c8c8c8 !important;
}

/*-----------------------End Sales Comparison Table -----------------------------------------*/
/*-----------------------Sales Sku Table -----------------------------------------*/

.skuTable .ant-table-thead > tr > th {
  border-left: 1px dotted black;
  border-top: 1px dotted black;
  border-bottom: 1px dotted black;
}

.skuTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td {
  border-left: 1px dotted black;
}

.skuTable .ant-table-thead > tr:nth-child(1) > th:nth-child(1),
.skuTable .ant-table-thead > tr:nth-child(1) > th:nth-child(2),
.skuTable .ant-table-thead > tr:nth-child(1) > th:nth-child(3),
.skuTable .ant-table-thead > tr:nth-child(1) > th:nth-child(4),
.skuTable .ant-table-thead > tr:nth-child(1) > th:nth-child(5) {
  border-bottom: 1px dotted black !important;
}

.skuTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(2n + 7) {
  border-left: 0;
}

.skuTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(4n + 8) {
  border-left: 0;
}

.skuTable .ant-table-thead > tr:nth-child(2) > th:nth-child(2n + 2),
.skuTable .ant-table-thead > tr:nth-child(2) > th:nth-child(4n + 3) {
  border-left: 0;
}

/** Good Required Filter */
.goods-required-filter span.st-btns {
  width: 25%;
}

.goods-required-filter .b-ftr-inr {
  width: 50%;
}

.goods-required-filter .r-left {
  width: 100%;
}

.goods-required-filter .report-container button {
  float: left;
  margin-left: 10px;
}

.goods-required-filter .dt-inr input[type="date"] {
  width: 40%;
}

/** End Good Required Filter */

/** Good Required Table */
.goodRequiredTable .ant-table-thead > tr > th:nth-child(1n + 8),
.b-grade-stock-report .ant-table-thead > tr > th:nth-child(1n + 7) {
  word-spacing: 9999px;
  line-height: 30px;
  border-right: none !important;
}

.goodRequiredTable .ant-table-thead>tr>th:nth-child(1),
.goodRequiredTable .ant-table-thead>tr>th:nth-child(8),
.b-grade-stock-report .ant-table-thead>tr>th:nth-child(7),
/* .sku-lookup-report .ant-table-thead>tr>th:nth-child(7), */
.ots-detail-report .ant-table-thead>tr>th:nth-child(9),
.ots-detail-report .ant-table-thead>tr>th:nth-child(9),
.production-detail .ant-table-thead>tr>th:nth-child(9) {
  border-left: 1px dotted;
}

.b-grade-stock-report .ant-table-thead > tr > th:nth-child(8),
.sku-lookup-report .ant-table-thead > tr > th:nth-child(8),
.ots-detail-report .ant-table-thead > tr > th:nth-child(8),
.production-detail .ant-table-thead > tr > th:nth-child(8) {
  border-left: none;
}

.goodRequiredTable .ant-table-thead > tr > th {
  border-top: 1px dotted;
  border-right: none !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border-bottom: 1px dotted;
  border-right: 0 !important;
}

.ant-table-thead > tr > th {
  vertical-align: bottom;
}

.ant-table {
  font-size: 12px !important;
}

/*OTS Detail Filter*/
.ots-detail-filter .filter-title + div span button {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

/**product upc report */
.product-upc-report .b-ftr-inr {
  float: left;
  width: 47%;
  margin-top: 10px;
  margin-right: 3%;
}

/* Start Business Dashboard */
.business-dashboard .ant-table-row-level-0 {
  counter-reset: section;
  line-height: 46px;
}

.business-dashboard .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 14.5005px;
}

.business-dashboard .ant-table-tbody > tr > td {
  padding: 0px 5px;
}

.business-dashboard tr.ant-table-row.ant-table-row-level-0 td {
  border-bottom: 0;
}

.business-dashboard .ant-table-row-level-1 > td:first-child:after {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  top: 13px;
}

.business-dashboard .ant-table-cell-with-append {
  font-weight: bold;
  text-transform: uppercase;
}

.business-dashboard + .total-results {
  top: 10px;
}

.business-dashboard .ant-table-pagination.ant-pagination {
  display: none;
}

.business-dashboard-test .ant-table-pagination.ant-pagination {
  display: block !important;
}

.business-dashboard #download-excel-button {
  /* display: none; */
}

/*----------------------Highlight Column------------------------------------------*/
.business-dashboard .ant-table-row td:nth-child(9),
.business-dashboard .ant-table-row td:nth-child(16) {
  background: #dafde5;
}

.business-dashboard .ant-table-row td:nth-child(17) {
  background: #d6f0f9;
}

/*Remove Hyper Link from these td*/
.business-dashboard .ant-table-row td:nth-child(2) button[sku_id="% VS LY"],
.business-dashboard .ant-table-row td:nth-child(2) button[sku_id="% VS Plan"],
.business-dashboard tr[data-row-key="20"] + tr td:nth-child(2) button,
.sku-lookup-report .ant-table-row td button:not([sku_id="Pending Ordesss"]),
.dash-body .ant-table-row td button[sku_id="Total"],
.ecommerce-by-region-table tr:last-child td button,
.sku-lookup-table-popup td button,
.sub-popup-table .ant-table-row td button {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black !important;
  font-weight: bold;
}

.sub-popup-table .ant-table-row td button,
.sku-lookup-table-popup td button {
  font-weight: normal;
}

.sku-lookup-report .ant-table-row td button[sku_id='Pending Order']

/* .sku-lookup-report .ant-table-row td button[sku_id='Int. Pending Order']
*/ {
  pointer-events: auto !important;
  cursor: pointer;
  text-decoration: none;
  font-weight: normal !important;
  color: #1890ff !important;
}

.sku-lookup-report .ant-table-row td:empty:nth-child(6) + td,
.sku-lookup-report .ant-table-row td:empty,
.sku-lookup-report .ant-table-row td:empty:nth-child(23) + td,
.sku-lookup-report .ant-table-row td:empty:nth-child(24) + td {
  background: #bfc2c5;
}

/* .sku-lookup-report .ant-table-row td:not(:empty) button[sku_id='OTS - Immediate'] {  
  font-weight: bold !important;
 } */

.sku-lookup-report .ant-table-row td:nth-last-child(4):not(:empty) + td + td {
  background: none;
}

.sku-lookup-report .ant-table-row td button:not([sku_id="Pending Order"]) {
  font-weight: normal;
}

/* End Business Dashboard */

/** Business Dashboard Region Wise */
.business-dashboard-region-wise .ant-table-row td:nth-child(9),
.business-dashboard-region-wise .ant-table-row td:nth-child(16),
.business-dashboard-region-wise .ant-table-row td:nth-child(17) {
  background: none;
}

.business-dashboard-region-wise .ant-table-row td:nth-child(11),
.business-dashboard-region-wise .ant-table-row td:nth-child(18) {
  background: #dafde5;
}

.business-dashboard-region-wise .ant-table-row td:nth-child(19) {
  background: #d6f0f9;
}

.business-dashboard-region-wise .ant-table-pagination.ant-pagination {
  display: none;
}

/** End Business report Region wise */

.stock-filter-layout .report-container {
  max-width: 572px;
}

.stock-filter-layout .b-ftr-inr label {
  width: 52px;
}

.stock-filter-layout .report-container button {
  float: left;
  margin-left: 10px;
}

.stock-filter-layout .b-ftr-inr {
  width: 100%;
}

.stock-filter-layout .r-left {
  width: 100%;
}

/** Filters One Column Layout */
.one-column-layout-filter .report-container {
  min-height: auto;
}

.one-column-layout-filter .r-left {
  width: 100%;
}

.one-column-layout-filter .report-container {
  max-width: 659px;
}

.one-column-layout-filter .report-container button {
  float: left;
  margin-left: 10px;
}

.report-container.group {
  min-height: auto;
}

/*Custom Loader */
.ant-spin-nested-loading > div > .ant-spin {
  top: 450px;
}

.ant-spin-blur .ant-table {
  display: none;
}

.loader {
  color: #1b1c1f;
  position: relative;
  top: 5px;
  left: auto;
  font-size: 20px;
  text-align: center;
  opacity: 0.5;
  display: inline-block;
  width: 20rem;
  font-size: 41px;
  height: 20rem;
  text-align: left;
  padding-left: 105px;
  line-height: 15rem;
  border-radius: 50%;
}

#progress-0,
#progress-0:before {
  height: 5px;
  width: 60%;
  margin: 0 auto;
  text-align: center;
}
#progress-0 {
  background-color: #607799;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  font-size: 30px;
  color: white;
}
#progress-0:before {
  background-color: #c8c8c8;
  content: "";
  -webkit-animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
@-webkit-keyframes running-progress {
  0% {
    margin-left: 0px;
    margin-right: 100%;
  }
  50% {
    margin-left: 25%;
    margin-right: 0%;
  }
  100% {
    margin-left: 100%;
    margin-right: 0;
  }
}
@keyframes running-progress {
  0% {
    margin-left: 0px;
    margin-right: 100%;
  }
  50% {
    margin-left: 25%;
    margin-right: 0%;
  }
  100% {
    margin-left: 100%;
    margin-right: 0;
  }
}

.loader span {
  color: black;
  font-weight: bold;
  font-size: 15px;
}

.loader:after {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  width: 80%;
  height: 80%;
  border-color: #c8c8c8 #c8c8c8 #000 #000;
  border-style: solid;
  border-width: 10px;
  border-radius: 50%;
  /* animation: spinner-border 1.4s linear infinite; */
}

.popUpTableComparison .loader,
.popUpTable .loader {
  top: -166px;
  font-size: 22px !important;
  width: 6rem;
  font-size: 64px;
  height: 6rem;
  line-height: 5rem;
}

/* .popUpTableComparison .ant-table-thead > tr > th {
  border-bottom: 1px dotted black !important;
} */

.popUpTableComparison
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(7),
.popUpTableComparison
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(9),
  .popUpTableComparison
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(19)
  {

  border-left: none;
}

.popUpTableComparison .ant-table-thead > tr > th:nth-child(11){
  border-left:none;
}

/* .popUpTableComparison
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td:nth-child(8),
  .popUpTableComparison .ant-table-thead > tr > th:nth-child(10)
  {
  border-left: 1px dotted black !important; 
} */

.popUpTableComparison .loader::after,
.popUpTable .loader::after {
  border-bottom: 5px solid #000000;
  border-left: 5px solid #000000;
  border-right: 5px solid #c8c8c8;
  border-top: 5px solid #c8c8c8;
}

.popUpTableComparison .ant-spin-nested-loading > div > .ant-spin,
.popUpTable .ant-spin-nested-loading > div > .ant-spin {
  top: 0px !important;
}

.ant-modal-body .navigation-area {
  margin-top: -62px;
  margin-left: 183px;
  float: left;
}

.ant-modal-content .report-result-right-top {
  float: right;
  margin-top: -67px;
  margin-right: 15px;
}

.ant-modal-footer {
  display: none;
}

.ant-modal-close {
  top: -6px;
}

/*End Custom Loader */

/**Start Error Modal **/
.alert-modal {
  top: 230px !important;
}

.alert-modal .ant-modal-close {
  position: absolute;
  top: 8px;
  right: 18px;
}

.alert-modal .ant-modal-header,
.alert-modal .ant-modal-body {
}

.alert-modal .ant-alert-warning {
  background: white;
  border: none;
}

.alert-modal .ant-modal-content {
  /* border: 9px solid var(--warning);
  border-radius: 10px; */
}

.alert-modal .ant-modal-header {
  border-bottom: 0;
}

.alert-modal .ant-modal-close-x {
  width: 11px;
  font-size: 21px;
  line-height: 9px;
}

/** End Error Modal **/

/*Multiple Select Box */
.multiple-select-box + div {
  height: auto !important;
}

.css-1l659or-control,
.css-1683jqh-ValueContainer {
  height: auto !important;
}

/**Hide Sorting Icon */
.ant-table-column-sorter-full {
  display: none;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 2px 6px;
}

.ant-modal-title {
  white-space: pre-line;
}

/** Mobile Device */
@media only screen and (max-width: 768px) {
  .header-right .feed-back,
  .header-right .nav-item .nav-link {
    color: white;
  }

  .ecommerce-dashbaord .navigation-area {
    width: auto;
  }

  .ecommerce-dashbaord .report-data {
    top: 34px !important;
  }

  .header-inner {
    display: flex;
    position: absolute;
    background: #607799;
    height: 0;
    top: -15px;
    left: 27px;
    justify-content: center;
  }

  .f-logo {
    color: white;
    font-size: 32px !important;
  }

  /* .company-logo {
    background-image: url(../images/DL1961_Charcoal.svg);
  } */
  .company-logo svg {
    width: 150px !important;
    height: 28px !important;
    fill: white !important;
  }

  .company-logo svg path {
    width: 100px;
    fill: white !important;
  }

  .header-right .nav-item {
    z-index: 100;
  }

  .auto-select {
    float: left;
    width: 100%;
    margin-bottom: 7px;
  }

  .one-column-layout-filter .report-container button {
    float: right;
  }

  .report-container {
    top: 5px;
    width: 97%;
  }

  .total-results {
    left: 5px;
  }

  .stock-filter-layout .report-container button {
    float: right;
    margin-top: 6px;
  }

  .one-column-layout-filter .report-container {
    max-width: 548px;
  }

  span.st-btns {
    width: 100%;
  }

  .goods-required-filter span.st-btns {
    width: 100%;
  }

  .goods-required-filter .dt-inr input[type="date"] {
    width: 100%;
  }

  .goods-required-filter .b-ftr-inr {
    width: 100%;
  }

  .goods-required-filter .report-container button {
    float: right;
  }

  .goods-required-filter .b-ftr-inr [type="text"],
  [type="number"] {
    width: 100%;
    margin-bottom: 10px;
  }

  .header-right {
    float: right;
    margin-top: 3px;
    font-size: 14px;
  }

  .r-right,
  .r-left {
    width: 100%;
  }

  .report-container {
    max-width: 580px;
  }

  .report-data {
    top: 0px;
    padding: 0;
  }

  .dash-body {
    margin-top: 49px;
  }

  .desktop-dashboard {
    display: none;
  }

  .dashboard-cards {
    display: grid;
    font-size: 15px;
  }

  .dashboard-card {
    padding: 1rem;
    height: 3.5rem;
  }

  .dashboard-card span i {
    font-size: 29px;
    position: initial;
    top: auto !important;
    left: initial;
    right: initial;
    float: left;
    text-align: left;
  }

  .ant-spin-nested-loading > div > .ant-spin {
    top: 100px;
    text-align: center;
  }

  .dash-body .report-data {
    top: 0;
    padding: 0;
  }

  td.ant-table-cell {
    white-space: nowrap;
    overflow: hidden;
  }

  button#download-excel-button,
  .report-result-head h2 {
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    float: none;
  }

  .header-right {
    display: none;
  }

  .report-result-head {
    position: relative;
  }

  .report-result-head .navigation-area {
    margin-left: 0;
    margin-top: 15px;
    text-align: center;
    position: relative;
    left: 0;
  }

  .navigation-area a {
    padding: 6px;
    margin-bottom: 6px;
  }

  button#close-dialog-btn {
    width: 20px;
    height: 20px;
    margin-top: 7px;
  }

  .report-result {
    top: 37px;
  }

  .report-result-head .report-result-right-top {
    float: none;
    margin: 0 auto !important;
    width: 215px;
    margin-top: 10px !important;
  }

  .report-container h1 {
    font-size: 17px;
  }

  .report-container-header {
    padding: 5px;
    /* border: 17px solid white; */
  }

  .company-logo img {
    max-width: 120px;
  }

  .mainmenu a,
  .navbar-default .navbar-nav > li > a,
  .mainmenu ul li a,
  .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff;
    font-size: 13px !important;
  }

  .link-react {
    font-size: 12px !important;
    padding: 1px 5px 0px 0px;
  }

  /** Modal **/
  .ant-modal-title {
    font-size: 12px;
    text-align: center;
  }

  .ant-modal-content .report-result-right-top {
    float: none;
    margin-top: 4px;
    text-align: center;
    margin-bottom: 7px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content button#download-excel-button {
    float: none !important;
  }

  .loader {
    color: #1b1c1f;
    position: relative;
    top: 6px;
    left: 20px;
    font-size: 20px;
    text-align: center;
    opacity: 0.5;
    display: inline-block;
    width: 17rem;
    font-size: 21px;
    height: 17rem;
    text-align: left;
    padding-left: 81px;
    line-height: 12rem;
    border-radius: 50%;
  }
}

.navigation-area a {
  text-transform: uppercase;
  display: inline-block;
  margin-left: 6px;
  text-align: center;
  width: 109px;
  font-size: 11px !important;
  color: white !important;
  background: #607799;
}

.disabled {
  opacity: 0.5;
}

/*laptop size and ipod */

@media (max-width: 1632px) and (min-width: 786px) {
  .dash-body .report-result-right-top {
    margin-top: none;
  }

  .dash-body .report-data {
    top: 115px;
  }

  .navigation-area {
    position: initial;
    width: 58%;
    text-align: left;
    margin: 0 auto;
  }

  .navigation-area a {
    text-align: center;
    margin-top: -30px;
    position: initial;
    margin-bottom: 9px;
  }
}


/**commulative-report*/

.cumulative-report th[colspan] {
  text-align:left !important;
  border-bottom:0px !important;
  padding-left:26px;
}

.cumulative-report .ant-table-thead > tr > th {
  border-bottom: 0px dotted;

}


.business-dashboard .ant-table-row td:nth-child(9),
.business-dashboard .ant-table-row td:nth-child(16) {
  /* background: #dafde5; */
  background: #d4e9e9;
}

.business-dashboard .ant-table-row td:nth-child(17) {
  /* background: #d6f0f9; */
  background: #e8f2fc;
}


.business-dashboard-region-wise .ant-table-row td:nth-child(11),
.business-dashboard-region-wise .ant-table-row td:nth-child(18) {
  /* background: #dafde5; */
  background: #d4e9e9;
}

.business-dashboard-region-wise .ant-table-row td:nth-child(19) {
  /* background: #d6f0f9; */
  background: #e8f2fc;
}




.business-dashboard .ant-table-row-level-0  {
  /* background-color: #dbd4d4; */
  background-color: #e2dedd;
}

.business-dashboard .ant-table-row-level-1:nth-child(odd)  {
  background-color: #f1f0f6;
  /* background-color: #0015cf; */

}

.business-dashboard-region-wise .ant-table-row-level-1:nth-child(odd)  {
  background-color: #e2dedd;
  /* background-color: #0015cf; */
}

.business-dashboard-region-wise .ant-table-row-level-0  {
  background-color: #f1f0f6;
  
}

.sale-target-report .ant-table-row-level-0:nth-child(odd)  {
  background-color: #e2dedd !important;
}
